@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: Poppins, sans-serif;
    border: none;
    outline: none;
    text-decoration: none;
}

:root {
    --primary: #171717;
    --ico: #171717;
    --secoundary: white;
    --night-color: #202022;
    --day-color: white;
    --grey: #656565;
    --night-background: radial-gradient(circle at top left, #262629, #202022);
    --transition-time: 0.5s;
    --border: 1px solid rgba(0, 0, 0, 0.111);
    --light: #c4c4c4;
    --greeny: #16ceb9;
    --redish: #ff447c;
    --ticker-duration: 30s;
}

body.dark {
    --primary: #3f3f3f;
    --secoundary: #ffffff;
    --grey: #d4d4d4;
    --border: 1px solid #ffffff1c;
    --ico: #ffffff;
    background-color: black;
    transition: all 0.3s ease 0s;
}

[class*="grid"] {
    display: grid;
}

body.dark .search-bar input {
    color: var(--secoundary);
}
body {
    transition: 0.5s;
}
ul,
li {
    list-style: none;
}

/* html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 80px;
} */

body {
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    transition: all 0.5s ease 0s;
    overflow-x: hidden;
}

p,
a,
input,
select,
textarea,
button {
    font-size: 16px;
}

img,
video {
    max-width: 100%;
}

.container {
    max-width: 1400px;
    padding: 0px 16px;
    margin: auto;
    width: 100%;
}

button {
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--primary);
    color: var(--secoundary);
    height: 50px;
    border-radius: 50px;
}

.ico {
    cursor: pointer;
}

section {
    padding: 80px 0px;
}

main {
    position: relative;
}

main .container {
    padding-bottom: 50px;
    display: grid;
    grid-template-rows: auto 1fr;
    position: relative;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px;
}

.top .set,
.top .more {
    display: flex;
    align-items: center;
    gap: 3px;
    justify-content: flex-end;
}

.top svg {
    width: unset;
    height: 25px;
}

.ico {
    width: 50px;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    border: var(--border);
    display: flex;
    align-items: center;
    justify-content: center;
}

input {
    background: transparent;
}

.search-wrap {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6rem;
}

.logo svg {
    width: unset;
    height: 100px;
    animation: rotateLogo 15s linear infinite;
}

@keyframes rotateLogo {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.search-bar {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    height: -moz-fit-content;
    height: fit-content;
    border: var(--border);
    border-radius: 50px;
    overflow: hidden;
    max-width: 600px;
    width: 100%;
    position: relative;
}

.search-bar input {
    height: 100%;
    padding: 0px 1rem;
    width: 100%;
}

.search-bar button {
    height: 50px;
}

.b-0 {
    border: none;
    padding: 5px;
    background: white;
}

.switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 50px;
}

.switch .slider {
    position: absolute;
    height: 50px;
    width: 80px;
    border-radius: 50px;
    background-color: var(--night-color);
    cursor: pointer;
    transition: var(--transition-time);
    z-index: 99;
}

.switch .slider::before {
    position: absolute;
    left: 5px;
    bottom: 5px;
    height: 40px;
    width: 40px;
    content: "";
    border-radius: 50%;
    background-color: var(--day-color);
    transition: var(--transition-time);
}

#toggle {
    opacity: 0;
    touch-action: none;
}

#toggle:checked ~ .slider {
    background-color: var(--day-color);
}

#toggle:checked ~ .slider::before {
    background-color: var(--day-color);
    box-shadow: inset -20px -10px 0 0px var(--night-color);
    transform: translate(30px, 1px);
}

#toggle:checked ~ .slide-block {
    left: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

.slide-block {
    /* height: 100vh; */
    width: 150vw;
    position: absolute;
    top: 0px;
    background: var(--night-background);
    transition: var(--transition-time);
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}
button.Button__BaseButton-sc-gzrp8u-0.Button-sc-gzrp8u-1.ActionButton__StyledButton-sc-1534nmi-0.WQpqu.iVamHH.jqNrVu {
    background: var(--primary);
}
.type__TextWrapper-sc-d4rujs-0.type__TransitionTextWrapper-sc-d4rujs-1.kXqglw.iQifim.button.button-large.css-1w97v79 {
    color: white;
}
.slide-block.slide-out {
    left: -150vw;
    border-bottom-right-radius: 100%;
    border-top-right-radius: 100%;
}

.slide-block.slide-in {
    left: 0px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}

@keyframes ticker {
    0% {
        transform: translate3d(0px, 0px, 0px);
        visibility: visible;
    }
    100% {
        transform: translate3d(-200%, 0px, 0px);
    }
}
header {
    display: flex;
    flex-direction: column;
}

header div {
    position: relative;
    background: var(--primary);
    height: 30px;
}

.marquee__inner-wrap {
    padding: 0px;
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
}

.marquee__inner {
    height: 100%;
    align-items: center;
    display: flex;
    animation: 50s linear 0s infinite normal none running marquee;
}

.marquee__inner-2 {
    animation: 15s linear 0s infinite normal none running marquee;
}

.marquee__inner p {
    font-weight: 600;
    line-height: 30px;
    font-size: 14px;
    vertical-align: middle;
    white-space: nowrap;
    box-sizing: content-box;
    margin-right: 50px;
}

.marquee__inner:hover {
    animation-play-state: paused;
}

.darker {
    color: var(--secoundary);
}

@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.coin {
    margin-right: 4px;
    margin-left: 10px;
}

header img {
    height: 20px;
    margin-bottom: 0.1rem;
    line-height: 15px;
    vertical-align: middle;
}
button.ico {
    width: 50px;
    padding: 0px;
}

.top .more {
    width: 0rem;
    overflow: hidden;
    position: absolute;
    right: 6rem;
    background: white;
    border-radius: 50px;
    transition: all 0.3s ease 0s;
}

.top .set.active .more {
    transition: all 0.3s ease 0s;
    display: flex;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px -5px;
}
.set {
    flex: 1;
}
.cls {
    transition: all 0.3s ease 0s;
}

.top .set.active .cls {
    transform: rotate(45deg);
    transition: all 0.3s ease 0s;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: -3rem;
}
.buttons button{
    padding: 0px 20px;
    height: 40px;
}
.buttons button:nth-child(2) {
    border: var(--border);
    background: none;
    color: var(--primary);
}

body.dark .buttons button:nth-child(2) {
    color: var(--secoundary);
}

body.dark .top .set.active .more {
    background: var(--primary);
}
.top .set {
    position: relative;
}
.coming-soon{
    background: transparent !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.coming-soon img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
.coming-soon h5{
    font-size: 1.5rem;
    color: var(--secoundary);
    font-weight: 600;
    text-align: center;
}
@media (max-width: 1024px) {
    .container {
        padding: 0px 15px;
    }
}
@media (max-width: 660px) {
    [class*="grid"] {
        grid-template-columns: 1fr !important;
        gap: 50px !important;
    }
    .fix {
        grid-area: 1/1/2/2;
    }
    .top svg {
        height: 20px;
    }
    .ico {
        width: 40px;
    }
    .top button {
        height: 40px;
        padding: 0px 15px;
        font-size: 15px;
    }
    .switch {
        width: 70px;
        height: 40px;
    }
    .switch .slider {
        position: absolute;
        height: 40px;
        width: 70px;
    }
    .switch .slider::before {
        height: 30px;
        width: 30px;
    }
    .search-bar input {
        width: 100%;
    }
    .top {
        position: relative;
    }
    .more {
        position: absolute;
        flex-direction: column;
        right: -5px !important;
        top: 50px;
        border-radius: 50px;
        padding: 5px;
        overflow: hidden;
        display: none !important;
    }
    .top .set.active .more {
        animation: 0.3s linear 0s 1 normal none running fade;
        background: white;
        width: -moz-fit-content !important;
        width: fit-content !important;
        display: flex !important;
        z-index: 999 !important;
        gap: 5px !important;
    }
    button.p-2.h-7.space-x-1.flex.items-center.justify-center.border.rounded-2xl.border-white\/10.bg-black\/10.text-white\/30.fill-current,
    button.p-2.h-7.w-7.flex.items-center.justify-center.border.rounded-full.border-white\/10.bg-black\/10.text-white\/30.fill-current,
    .border.border-black\/50.fill-current.text-black.bg-black\/10.dark\:text-white-35.dark\:hover\:text-white\/50.dark\:border.dark\:border-white-35.dark\:hover\:border-white\/50.h-8.w-8.rounded-full.flex.items-center.justify-center.cursor-pointer.transition-all {
        background: var(--primary) !important;
    }
    button.relative.text-white.bg-\[\#191B1F\].dark\:bg-black\/50.w-full.mt-4.rounded-xl {
        background: var(--primary) !important;
    }
    button.Button__BaseButton-sc-gzrp8u-0.Button-sc-gzrp8u-1.ActionButton__StyledButton-sc-1534nmi-0.dFZgHW.cHToB.fwWSvM {
        background: var(--primary);
    }
    .type__TextWrapper-sc-d4rujs-0.type__TransitionTextWrapper-sc-d4rujs-1.bGSERA.divqpT.button.button-large.css-1w97v79 {
        color: white;
    }
    svg {
        fill: white;
    }
    @keyframes fade {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .logo svg {
        height: 50px;
    }
    .search-wrap {
        gap: 80px;
    }
    .search-bar button {
        font-size: 14px;
        padding: 0 20px;
    }
    button.ico {
        padding: 0;
    }
}
/* Uniswap widget */
.widgets-wrapper {
    width: 100%;
    /* max-width: 1900px; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    row-gap: 50px;
}

.widgets-wrapper .Uniswap .WidgetWrapper__StyledWidgetWrapper-sc-11krezp-0 {
    width: 100% !important;
    max-width: 100% !important;
    height: fit-content;
    box-shadow: none !important;
}

.widgets-wrapper .Uniswap .nRkwQ {
    display: none;
}

.widgets-wrapper .Uniswap .TokenOptions__OnHover-sc-xx1k3q-2 {
    display: none;
}

.widgets-wrapper .Uniswap .Button__BaseButton-sc-1soikk5-0.ijltYQ {
    background: #000 !important;
}

.widgets-wrapper
    .Uniswap
    .Button__BaseButton-sc-1soikk5-0.ijltYQ
    .type__TextWrapper-sc-16386l-0 {
    color: #fff !important;
}

.widgets-wrapper .integrated-terminal {
    border-radius: 15px;
    border: var(--border);
    background: #f3f3f3;
    overflow: hidden;
    min-height: 400px;
    max-height: 400px;
}
.chatbot {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.chatbot-terminal {
    position: relative;
    background: none !important;
}
.chatbot-terminal img{
    position: absolute;
    z-index: -1;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
    object-fit: cover;
}
.prompt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 100%;
    text-align: left;
}
.responses {
    display: flex;
    flex-direction: column;
    max-height: 330px;
    width: 100%;
    overflow-y: auto;
}
.response {
    margin: 10px;
    padding: 10px;
    background-color: #e0e0e0ed;
    border-radius: 10px;
    width: calc(100% - 20px);
    text-align: left;
}
.response.even{
    background-color: #f3f3f3bd;
    text-align: right;
}

.prompt input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
}

@media only screen and (max-width: 1024px) {
    .widgets-wrapper {
        max-width: 450px;
        grid-template-columns: 1fr;
    }
    .buttons{
        margin-bottom: 1rem;
    }
}

.unstyled-link {
    all: unset;
    display: block;
    cursor: pointer;
    overflow-wrap: anywhere;
}
.result-header {
    display: flex;
    gap: 10px;
    align-items: start;
}
.result-description {
    font-size: 0.9rem;
    color: var(--grey);
    margin-top: 5px;
}
.result-description > p {
    display: inline;
    font-size: 0.9rem;
    margin-top: 5px;
}
.result-profile-name {
    font-size: 1rem;
    font-weight: 600;
}
.result-profile-url {
    font-size: 0.8rem;
    color: var(--grey);
    margin-top: 3px;
}
.results {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 50px;
    margin-top: 50px;
}
.results.results-embedded{
  grid-template-columns: 1fr;
}
.results.results-embedded .result {
background-color: #ffffffe2;
padding: 10px;
border-radius: 5px;
}
.p-50 {
    padding: 50px;
}
.search-div {
    display: flex;
    gap: 20px;
    align-items: center;
}
.search-wrapper-div {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
}
#live-count {
    font-size: 1rem;
    color: var(--grey);
    /* border: 2px solid var(--grey); */
    padding: 5px 10px;
    border-radius: 5px;
    width: fit-content;
}
#live-count p {
    font-size: 1rem;
    color: var(--grey);
}
.count {
    font-weight: 600;
    width: fit-content;
    margin: 0 auto;
}
footer {
    display: flex;
    padding: 0 0 50px 50px;
}
@media screen and (max-width: 480px) {
    footer {
        padding: 0 0 10px 10px;
    }
    #live-count {
        padding: 5px 5px;
    }
    #live-count p {
        font-size: 0.8rem;
        color: var(--grey);
    }
    .p-50 {
        padding: 20px;
    }
}
.logo-insta {
    padding: 5px;
    width: 36px;
    height: 36px;
}
.logo-x {
    width: 50px;
    height: 50px;
}
.home-button {
    background-color: #29dc47;
    flex-shrink: 0;
}
.switch-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}
.no-results {
    font-size: 1.2rem;
    width: fit-content;
    margin: 100px auto;
    color: var(--primary);
}
.loader {
    position: relative;
    width: 120px;
    height: 90px;
    margin: 100px auto;
}

.loader:before {
    content: "";
    position: absolute;
    bottom: 30px;
    left: 50px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #2a9d8f;
    animation: loading-bounce 0.5s ease-in-out infinite alternate;
}

.loader:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 7px;
    width: 45px;
    border-radius: 4px;
    box-shadow: 0 5px 0 #f2f2f2, -35px 50px 0 #f2f2f2, -70px 95px 0 #f2f2f2;
    animation: loading-step 1s ease-in-out infinite;
}
#moonpay-react-sdk-embedded-frame {
    margin: 0 !important;
    width: 100% !important;
}
.integrated-terminal iframe {
    width: 100%;
    height: 100%;
}
.integrated-terminal.charts-terminal {
    overflow-x: hidden;
    overflow-y: auto;
}
.integrated-terminal.search-terminal {
    padding: 10px;
    position: relative;
    background-color: #00000027;
}
.search-bg{
    position: absolute;
    z-index: -1;
    top: 23%;
    left: 1%;
    width: 100%;
    height: 70%;

}
.integrated-terminal .search-bar {
    background-color: #eeecec;
    width: 100%;
    grid-template-columns: 1fr auto;
}
.bobi-block{
    background: transparent !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 60px;
    background-size: cover;
    position: relative;
}
.bobi-block img{
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(0.7);
}
.bobi-block h4{
    color: var(--secoundary);
    font-size: 1.1rem;
    font-weight: 600;
}
.bobi-block p{
    font-size: 0.7rem;
    text-align: center;
    color: var(--secoundary);
}
.bobi-block button{
    background: #3555c9;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
}
.bobi-block button a{
    color: white;
    text-decoration: none;
}
@keyframes loading-bounce {
    0% {
        transform: scale(1, 0.7);
    }

    40% {
        transform: scale(0.8, 1.2);
    }

    60% {
        transform: scale(1, 1);
    }

    100% {
        bottom: 140px;
    }
}

@keyframes loading-step {
    0% {
        box-shadow: 0 10px 0 rgba(0, 0, 0, 0), 0 10px 0 #f2f2f2,
            -35px 50px 0 #f2f2f2, -70px 90px 0 #f2f2f2;
    }

    100% {
        box-shadow: 0 10px 0 #f2f2f2, -35px 50px 0 #f2f2f2, -70px 90px 0 #f2f2f2,
            -70px 90px 0 rgba(0, 0, 0, 0);
    }
}
